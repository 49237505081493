import React, { useEffect, useState } from "react";
import { SubNavItem, ListItem, UL } from "./styles";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetNav } from "../../StateManagement/Reducers/ScheduleState";
import { useDispatch } from "react-redux";

const SubSidebar = ({ onMouseEnter, onMouseLeave, demo }) => {
  let params = useParams();
  let ch = 1;
  const [navData1, setNavData1] = useState("iconclose");
  const dispatch = useDispatch();

  const handleInputChange = (iconName) => (e) => {
    dispatch(GetNav(iconName));
  };
  var NavData1 = useSelector((state) => state.Schedule?.NavData);
  var SideNavData = useSelector((state) => state.Schedule?.SideNavData);
  useEffect(() => {
    if (NavData1 !== "") {
      setNavData1(NavData1);
    }
  }, [NavData1]);
  useEffect(() => {
    dispatch(GetNav("iconclose"));
  }, []);

  const menuItems = [
    {
      label: "Patient Dashboard",
      path: `patientdashboard/${params.pid}`,
      top: true,
    },
    { label: "Demographics", path: `demographics/${params.pid}` },
    { label: "Appointment", path: `appointment/${params.pid}` },
    { label: "Bill and Insurance", path: `billandinsurance/${params.pid}` },
    { label: "Documents", path: `documents/${params.pid}` },
    { label: "Problems", path: `problems/${params.pid}` },
    { label: "Allergies", path: `allergies/${params.pid}` },
    { label: "Medication", path: `medication/${params.pid}` },
    { label: "Immunizations", path: `immunization/${params.pid}` },
    { label: "Family History", path: `familyhistory/${params.pid}` },
    { label: "Past Medical History", path: `pastmedicalhistory/${params.pid}` },
    { label: "Lab Order", path: `procedureorder/${params.pid}` },
    { label: "E-Prescriptions", path: `senderx/${params.pid}/${ch}` },
    { label: "Client Portal Access", path: `clientportalaccess/${params.pid}` },
  ];

  return (
    <div>
      <UL
        backgroundColor="white"
        position="relative"
        top="0px"
        listStyleType="none"
        padding="0 0 16px 0"
        marginBottom={
          (SideNavData === "demo" ||
            SideNavData === "immune" ||
            SideNavData !== "demographics") &&
          "0px !important"
        }
        width={
          (demo === "demo" && navData1 === "iconopen" && "92.6%") ||
          (demo === "dashboard" && navData1 === "iconopen" && "92.6%") ||
          (navData1 === "iconclose" && "92.6%")
        }
        borderTop="1px solid rgba(46, 46, 46, 0.25)"
        height={
          SideNavData === "immune"
            ? "129vh"
            : SideNavData === "demo"
            ? "fit-content"
            : "100vh"
        }
        textAlign="left"
        paddingLeft="20px"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        borderRadius="0 0 10px 10px"
      >
        {menuItems.map((item, index) => (
          <ListItem
            key={index}
            width="100%"
            marginTop={item.top ? "18px" : "18px"}
            onClick={handleInputChange("iconclose")}
          >
            <SubNavItem
              to={{
                pathname: `/patient/${item.path}`,
              }}
              exact
            >
              {item.label}
            </SubNavItem>
          </ListItem>
        ))}
      </UL>
    </div>
  );
};

export default SubSidebar;
