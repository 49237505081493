import { call, delay, put } from "redux-saga/effects";
import {
  SetRevision,
  SetFacilitySearchData,
  SetAppointmentSearchData,
  SetFacilitySearchNoResult,
  SetAppointmentTenData,
  SetIncomingFax,
  SetSearchIncomingFax,
  SetInsertIncomingFax,
  SetOutgoingFax,
  SetInsertOutgoingFax,
  SetProvDataFax,
  SetSaveFaxQueue,
  SetOutgoingFaxDetails,
  SetIncomingFaxDetails,
  SetFaxAssign,
  SetFaxProviderdetails,
  setPreviewDownload,
  setPreviewDownload_2out,
  setPatList,
  setProvidersList,
  setReferalData,
  SetRefferal,
  setTenReferalData,
  setTenReferalData1,
  SetTenReferalContact,
  SetRefMngmtPreview,
  SetUrlimg,
  setNoResult,
  setProviderList,
  setReferrerDiagnosis,
  setPatientDetails,
  changeAlertModal,
  setSingleReferral,
  SetReferalContact,
  SetSingleReferalContact,
  SetRefContactemailcheck,
  SetRefContactfaxcheck,
  SetRefContactphonecheck,
  SetRefContactnpicheck,
  SetRefContactemptyvalidation,
  SetRefContactemptyphone,
  SetRefContactemptyfax,
  SetRefContactemptynpi,
  setSendFaxMail,
  setReferalDataOne,
  setReferralDownload,
  setReferralPreview,
  setDeleteTempDocumentData,
  setFaxMailProviders,
  SetGetMsgList,
  changeShowAdd,
  SetSurgicalhistory,
  SetSurgicaldata,
  SetchangeSurgicaldata,
  SethandledeleteSurgicaldata,
  Seteditsaved,
  SetpostSocialhistory,
  SetSocialhistory,
} from "../../StateManagement/Reducers/PatientState";
import {
  RevisionApi,
  FacilitySearchDataApi,
  AppointmentSearchDataApi,
  NoteUpdateApi,
  GetSingleIncomingFaxApi,
  SearchIncomingFaxApi,
  InsertIncomingFaxApi,
  GetSingleOutgoingFaxApi,
  InsertOutgoingFaxApi,
  ProvFaxDataApi,
  SaveFaxQueueApi,
  GetOutgoingFaxApi,
  GetIncomingFaxApi,
  IncomingFaxProviderApi,
  IncomingFaxAssignApi,
  PreviewDownloadApi_1,
  PreviewDownloadApi_2,
  PatListApi,
  ProvidersListApi,
  ReferalDataApi,
  RefferalListApi,
  Ref_mngmt_PreviewApi,
  Ref_mngmt_UrlApi,
  referrerProviderSearchApi,
  referrerDiagnosisApi,
  PatientDetailsApi,
  ReferrelInsertApi,
  singleReferrerApi,
  updateReferralApi,
  ReferalDeleteApi,
  ReferalContactApi,
  ReferalInsertContactApi,
  ReferalUpdateContactApi,
  ReferalDeleteContactApi,
  ReferalContactSingleApi,
  RefContactEmailCheckApi,
  SendFaxMailApi,
  ReferralDataOneApi,
  ReferralDownloadApi,
  ReferralPreviewApi,
  DeleteTempApi,
  ProvidersFaxMailApi,
  ProviderListApi,
  SurgicalhistoryApi,
  SurgicaldataApi,
  changeSurgicaldataApi,
  deleteSurgicaldataApi,
  editsaveApi,
  SocialhistorypostApi,
  SocialhistorygetApi,
} from "../Apis/PatientApi";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

const DBHost =
  localStorage.getItem("DBHost") &&
  Decrypt_Value(localStorage.getItem("DBHost"), "vozo");

export function* Revision({ payload }) {
  let encounter = payload;
  try {
    const res = yield call(RevisionApi, encounter);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetRevision(decryptResponse));
    }
  } catch (e) {
    yield put(SetRevision([]));
    console.log(e.message);
  }
}

export function* FacilitySearchData({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(FacilitySearchDataApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (decryptResponse.length < 1) {
        yield put(SetFacilitySearchNoResult(""));
      } else {
        yield put(SetFacilitySearchNoResult("1"));
      }
      yield put(SetFacilitySearchData(decryptResponse));
    }
  } catch (e) {
    yield put(SetFacilitySearchData([]));
    console.log(e.message);
  }
}
export function* Surgicalhistory({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");

  try {
    const res = yield call(SurgicalhistoryApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");

      yield put(SetSurgicalhistory(decryptResponse));
    }
  } catch (e) {
    yield put(SetSurgicalhistory([]));
    console.log(e.message);
  }
}
export function* Surgicaldata({ payload }) {
  try {
    const res = yield call(SurgicaldataApi, payload.pid);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetSurgicaldata(decryptResponse));
    }
  } catch (e) {
    yield put(SetSurgicaldata([]));
    console.log(e.message);
  }
}
export function* changeSurgicaldata({ payload }) {
  try {
    const res = yield call(changeSurgicaldataApi, payload.id);
    console.log("res", res);

    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      console.log(decryptResponse, "decryptResponse of edit");
      yield put(SetchangeSurgicaldata(decryptResponse));
    }
  } catch (e) {
    yield put(SetchangeSurgicaldata([]));
    console.log(e.message);
  }
}
export function* editsave({ payload }) {
  console.log({ payload }, "editsavepayload");
  const Data = Encrypt_Value(payload, "vozo");

  try {
    const res = yield call(editsaveApi, Data);
    console.log("res", res);

    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      console.log(decryptResponse, "decryptResponse of edit");
      yield put(Seteditsaved(decryptResponse));
    }
  } catch (e) {
    yield put(Seteditsaved([]));
    console.log(e.message);
  }
}
export function* deleteSurgicaldata({ payload }) {
  try {
    const res = yield call(deleteSurgicaldataApi, payload.id);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      console.log(decryptResponse, "decryptResponse");
      yield put(SethandledeleteSurgicaldata(decryptResponse));
    }
  } catch (e) {
    yield put(SethandledeleteSurgicaldata([]));
    console.log(e.message);
  }
}

export function* Socialhistorypost({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");

  try {
    const res = yield call(SocialhistorypostApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");

      yield put(SetpostSocialhistory(decryptResponse));
    }
  } catch (e) {
    yield put(SetpostSocialhistory([]));
    console.log(e.message);
  }
}
export function* SocialHistoryget({ payload }) {
  console.log(payload.pid, "payload ");
  try {
    const res = yield call(SocialhistorygetApi, payload.pid);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetSocialhistory(decryptResponse));
    }
  } catch (e) {
    yield put(SetSocialhistory([]));
    console.log(e.message);
  }
}

export function* AppointmentSearchData({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(AppointmentSearchDataApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (payload.offset === 0) {
        yield put(SetAppointmentSearchData(decryptResponse));
      } else {
        yield put(SetAppointmentTenData(decryptResponse));
      }
    }
  } catch (e) {
    if (payload.offset === 0) {
      yield put(SetAppointmentSearchData([]));
    }
    console.log(e.message);
  }
}

export function* NoteUpdateWorker({ payload }) {
  try {
    const res = yield call(NoteUpdateApi, payload);
  } catch (e) {
    console.log(e.message);
  }
}

export function* IncomingFaxget({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(GetSingleIncomingFaxApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetIncomingFax(decryptResponse));
    }
  } catch (e) {
    yield put(SetIncomingFax([]));
  }
}

export function* EmptyProviderListReferalWorkers() {
  yield put(setFaxMailProviders([]));
}

export function* RefferalList() {
  try {
    const res = yield call(RefferalListApi);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");

      yield put(SetRefferal(decryptResponse));
    }
  } catch (e) {
    yield put(SetRefferal([]));
    console.log(e.message);
  }
}

export function* IncomingFaxsearch({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(SearchIncomingFaxApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetSearchIncomingFax(decryptResponse));
    }
  } catch (e) {
    yield put(SetSearchIncomingFax([]));
    console.log(e.message);
  }
}

export function* IncomingFaxinsert({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(InsertIncomingFaxApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetInsertIncomingFax(decryptResponse));
    }
  } catch (e) {
    yield put(SetInsertIncomingFax([]));
    console.log(e.message);
  }
}
export function* OutgoingFaxget({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(GetSingleOutgoingFaxApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetOutgoingFax(decryptResponse));
    }
  } catch (e) {
    yield put(SetOutgoingFax([]));
  }
}
export function* ProvidersListWorker({ payload }) {
  const encData = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ProvidersListApi, encData);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setProvidersList(Data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setProvidersList([]));
  }
}

export function* PatListWorker({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(PatListApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setPatList(Data));
      yield put(setNoResult(Data));
    }
  } catch (e) {
    yield put(setNoResult(""));
    yield put(setPatList([]));
    console.log(e.message);
  }
}

export function* ReferalDataWorker({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  const type = payload.type;
  try {
    const res = yield call(ReferalDataApi, credential);

    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozo");
      if (payload.offset === 0) {
        yield put(setReferalData({ type, data: Response }));
      } else {
        yield put(setTenReferalData({ type, data: Response }));
      }
    }
  } catch (e) {
    if (payload.offset === 0) {
      yield put(setReferalData([]));
      console.log(e.message);
    }
  }
}

export function* OutgoingFaxinsert({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(InsertOutgoingFaxApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetInsertOutgoingFax(decryptResponse));
    }
  } catch (e) {
    yield put(SetInsertOutgoingFax([]));
    console.log(e.message);
  }
}
export function* IncomingFaxAssign({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");

  try {
    const res = yield call(IncomingFaxAssignApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetFaxAssign(decryptResponse));
    }
  } catch (e) {
    yield put(SetFaxAssign([]));
    console.log(e.message);
  }
}
export function* IncomingFaxProvider({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(IncomingFaxProviderApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");

      yield put(SetFaxProviderdetails(decryptResponse));
    }
  } catch (e) {
    yield put(SetFaxProviderdetails([]));
  }
}
export function* ReferalDeleteList({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  const id = payload.id;
  const type = payload.type;
  try {
    const res = yield call(ReferalDeleteApi, id);
    if (res.status === 200) {
      const res1 = yield call(ReferalDataApi, credential);
      if (res1.status === 200) {
        const Data = Decrypt_Value(res1.data, "vozo");
        yield put(setReferalData({ type, data: Data }));
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* Refmangmnt_Preview({ payload }) {
  let details = payload;
  try {
    const res = yield call(Ref_mngmt_PreviewApi, details);
    if (res.status === 200) {
      var DocUrl =
        process.env.REACT_APP_BACKEND_FILE_ACCESS +
        "/sites/" +
        DBHost +
        "/documentimg/" +
        res.data.data.img_url;
      let imgfile = { url: DocUrl };
      const res1 = yield call(Ref_mngmt_UrlApi, imgfile);
      if (res1.status === 200) {
        yield put(SetUrlimg(res1.data));
      }
      //const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetRefMngmtPreview(res.data));
    }
  } catch (e) {
    yield put(SetRefMngmtPreview([]));
    console.log(e.message);
  }
}

export function* ProviderListWorker({ payload }) {
  const { type, data } = payload;
  const encrypt = Encrypt_Value(data, "vozo");
  try {
    const res = yield call(referrerProviderSearchApi, encrypt);
    if (res.status === 200) {
      if (type === "referred from") {
        const Data = Decrypt_Value(res.data, "vozo");
        yield put(setProviderList({ type: "from", data: Data }));
        yield put(setNoResult(Data));
      } else if (type === "referred to") {
        const Data = Decrypt_Value(res.data, "vozo");
        yield put(setProviderList({ type: "to", data: Data }));
        yield put(setNoResult(Data));
      } else if (type === "initial") {
        const Data = Decrypt_Value(res.data, "vozo");
        yield put(setProviderList({ type: "initial", data: Data }));
      }
    }
  } catch (e) {
    console.log(e.message);
    if (type === "referred from") {
      const Data = Decrypt_Value([], "vozo");
      yield put(setProviderList({ type: "from", data: Data }));
      yield put(setNoResult(""));
    } else if (type === "referred to") {
      const Data = Decrypt_Value([], "vozo");
      yield put(setProviderList({ type: "to", data: Data }));
      yield put(setNoResult(""));
    } else if (type === "initial") {
      const Data = Decrypt_Value([[], []], "vozo");
      yield put(setProviderList({ type: "initial", data: Data }));
    }
  }
}

export function* ReferrerDiagnosisWorker({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(referrerDiagnosisApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setReferrerDiagnosis(Data));
      yield put(setNoResult(Data));
    }
  } catch (e) {
    yield put(setNoResult(""));
    yield put(setReferrerDiagnosis([]));
    console.log(e.message);
  }
}

export function* PatientDetailsWorker({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(PatientDetailsApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setPatientDetails(Data));
    }
  } catch (e) {
    yield put(setPatientDetails([]));
    console.log(e.message);
  }
}

export function* ProviderFaxDataWorker({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ProvFaxDataApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetProvDataFax(decryptResponse));
    }
  } catch (e) {
    yield put(SetProvDataFax([]));
  }
}
export function* SingleReferralWorker({ payload }) {
  try {
    const res = yield call(singleReferrerApi, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setSingleReferral([Data]));
    }
  } catch (e) {
    yield put(setSingleReferral([]));
    console.log(e.message);
  }
}

export function* UpdateReferralWorker({ payload }) {
  let type = payload.source === "Out" ? "1" : "2";
  let refereshData = Encrypt_Value(
    {
      pid: "",
      ProviderFrom: "",
      ProviderTo: "",
      module: "1",
      limit: "40",
      offset: "0",
      type: type,
      status: "",
    },
    "vozo"
  );
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(updateReferralApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      if (isNaN(Data)) {
        yield put(
          changeAlertModal({
            type: 2,
            value: true,
            mesg: "Failed to update referral",
          })
        );
        yield delay(2000);
        yield put(changeAlertModal({ type: 2, value: false, mesg: "" }));
      } else {
        const res = yield call(ReferalDataApi, refereshData);

        if (res.status === 200) {
          const Response = Decrypt_Value(res.data, "vozo");
          yield put(setReferalData({ type, data: Response }));
        }

        yield put(
          changeAlertModal({
            type: 1,
            value: true,
            mesg: "Referral updated successfully",
            preview: 0,
            show: false,
          })
        );
        yield delay(2000);
        yield put(
          changeAlertModal({
            type: 1,
            value: false,
            mesg: "",
            preview: Data,
            show: true,
          })
        );
      }
    }
  } catch (e) {
    yield put(
      changeAlertModal({
        type: 2,
        value: true,
        mesg: "Failed to update referral",
      })
    );
    yield delay(2000);
    yield put(changeAlertModal({ type: 2, value: false, mesg: "" }));
    console.log(e.message);
  }
}

export function* EmptyProviderList() {
  yield put(SetProvDataFax([]));
}

export function* SaveFaxQueueWorker({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(SaveFaxQueueApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetSaveFaxQueue(decryptResponse));
    }
  } catch (e) {
    yield put(SetSaveFaxQueue(""));
    console.log(e.message);
  }
}

export function* IncomingFax({ payload }) {
  try {
    const Data = Encrypt_Value(payload, "vozo");
    const res = yield call(GetIncomingFaxApi, Data);

    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozo");
      yield put(SetIncomingFaxDetails(Response));
    }
  } catch (e) {
    yield put(SetIncomingFaxDetails([]));
  }
}

export function* OutgoingFax({ payload }) {
  try {
    const Data = Encrypt_Value(payload, "vozo");
    const res = yield call(GetOutgoingFaxApi, Data);
    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozo");
      yield put(SetOutgoingFaxDetails(Response));
    }
  } catch (e) {
    yield put(SetOutgoingFaxDetails([]));
  }
}

export function* PreviewDownloadApi({ payload }) {
  try {
    const res = yield call(PreviewDownloadApi_1, payload);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozo");
      yield put(setPreviewDownload(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PreviewDownload_2out_Api({ payload }) {
  try {
    const res = yield call(PreviewDownloadApi_2, payload);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozo");
      yield put(setPreviewDownload_2out(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* ReferralInsertWorker({ payload }) {
  let type = payload.source === "Out" ? "1" : "2";
  let refereshData = Encrypt_Value(
    {
      pid: "",
      ProviderFrom: "",
      ProviderTo: "",
      module: "1",
      limit: "40",
      offset: "0",
      type: type,
      status: "",
    },
    "vozo"
  );
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ReferrelInsertApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      if (isNaN(Data)) {
        yield put(
          changeAlertModal({
            type: 2,
            value: true,
            mesg: "Failed to insert referral",
          })
        );
        yield delay(2000);
        yield put(changeAlertModal({ type: 2, value: false, mesg: "" }));
      } else {
        const res = yield call(ReferalDataApi, refereshData);

        if (res.status === 200) {
          const Response = Decrypt_Value(res.data, "vozo");
          yield put(setReferalData({ type, data: Response }));
        }

        yield put(
          changeShowAdd({
            value: false,
            id: Data,
            type: false,
          })
        );
        yield put(
          changeAlertModal({
            type: 1,
            value: true,
            mesg: "Referral inserted successfully",
            preview: 0,
            show: false,
          })
        );
        yield delay(2000);
        yield put(
          changeAlertModal({
            type: 1,
            value: false,
            mesg: "",
            preview: Data,
            show: true,
          })
        );
      }
    }
  } catch (e) {
    yield put(
      changeAlertModal({
        type: 2,
        value: true,
        mesg: "Failed to insert referral",
      })
    );
    yield delay(2000);
    yield put(changeAlertModal({ type: 2, value: false, mesg: "" }));
    console.log(e.message);
  }
}

//nnn//

export function* ReferalContactList({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ReferalContactApi, credential);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Data = Decrypt_Value(res.data, "vozo");
        if (payload.offset === 0) {
          if (Data === "failed") {
            yield put(SetReferalContact(Data));
          } else {
            yield put(SetReferalContact(Data));
          }
        } else {
          if (Data === "failed") {
            yield put(SetTenReferalContact(Data));
          } else {
            yield put(SetTenReferalContact(Data));
          }
        }
      } else {
        if (payload.offset === 0) {
          yield put(SetReferalContact([]));
        }
      }
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetReferalContact([]));
  }
}

export function* ReferalContactSingleList({ payload }) {
  try {
    const res = yield call(ReferalContactSingleApi, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(SetSingleReferalContact(Data));
    }
  } catch (e) {
    yield put(SetSingleReferalContact({}));
    console.log(e.message);
  }
}
export function* ReferalInsertContactList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ReferalInsertContactApi, requiredData);
    if (res.status === 200) {
      const res = yield call(ReferalContactApi, payload);
      if (res.status === 200) {
        const Response = Decrypt_Value(res.data, "vozo");
        yield put(SetReferalContact(Response));
      }
    }
  } catch (e) {
    yield put(SetReferalContact([]));
    console.log(e.message);
  }
}

export function* ReferalUpdateContactList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ReferalUpdateContactApi, requiredData);
    if (res.status === 200) {
      const res = yield call(ReferalContactApi, payload);
      if (res.status === 200) {
        const Response = Decrypt_Value(res.data, "vozo");
        yield put(SetReferalContact(Response));
      }
    }
  } catch (e) {
    yield put(SetReferalContact([]));
    console.log(e.message);
  }
}
export function* ReferalDeleteContactList({ payload }) {
  try {
    const res = yield call(ReferalDeleteContactApi, payload);
    if (res.status === 200) {
      const res = yield call(ReferalContactApi, payload);
      if (res.status === 200) {
        const Response = Decrypt_Value(res.data, "vozo");
        yield put(SetReferalContact(Response));
      }
    }
  } catch (e) {
    yield put(SetReferalContact([]));
    console.log(e.message);
  }
}

export function* RefContactemailcheck({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(RefContactEmailCheckApi, requiredData);
    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozo");
      yield put(SetRefContactemailcheck(Response));
    }
  } catch (e) {
    yield put(SetRefContactemailcheck(""));
    console.log(e.message);
  }
}
export function* RefContactfaxcheck({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(RefContactEmailCheckApi, requiredData);
    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozo");
      yield put(SetRefContactfaxcheck(Response));
    }
  } catch (e) {
    yield put(SetRefContactfaxcheck(""));
    console.log(e.message);
  }
}
export function* RefContactnpicheck({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(RefContactEmailCheckApi, requiredData);
    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozo");
      yield put(SetRefContactnpicheck(Response));
    }
  } catch (e) {
    yield put(SetRefContactnpicheck(""));

    console.log(e.message);
  }
}
export function* RefContactphonecheck({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(RefContactEmailCheckApi, requiredData);
    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozo");
      yield put(SetRefContactphonecheck(Response));
    }
  } catch (e) {
    yield put(SetRefContactphonecheck(""));
    console.log(e.message);
  }
}
export function* RefContactemptyvalidation() {
  yield put(SetRefContactemptyvalidation(""));
}

export function* RefContactemptyphone() {
  yield put(SetRefContactemptyphone("Phone Number not found"));
}
export function* RefContactemptyfax() {
  yield put(SetRefContactemptyfax("Fax not found"));
}
export function* RefContactemptynpi() {
  yield put(SetRefContactemptynpi("NPI not found"));
}

export function* SendFaxMailWorker({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(SendFaxMailApi, credential);
    if (res.status === 200) {
      yield put(setSendFaxMail(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setSendFaxMail(""));
  }
}

export function* ReferralDataOneWorker({ payload }) {
  const encrypted = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ReferralDataOneApi, encrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setReferalDataOne(Data));
    }
  } catch (e) {
    yield put(setReferalDataOne([]));
    console.log(e.message);
  }
}

export function* ReferralDownloadWorker({ payload }) {
  const encrypted = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ReferralDownloadApi, encrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setReferralDownload(Data));
    }
  } catch (e) {
    yield put(setReferralDownload(""));
    console.log(e.message);
  }
}

export function* ReferralPreviewWorker({ payload }) {
  const encrypted = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ReferralPreviewApi, encrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setReferralPreview(Data));
    }
  } catch (e) {
    yield put(setReferralPreview(""));
    console.log(e.message);
  }
}

export function* DeleteTempWorker({ payload }) {
  const encrypted = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(DeleteTempApi, encrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setDeleteTempDocumentData(Data));
    }
  } catch (e) {
    yield put(setDeleteTempDocumentData(""));
    console.log(e.message);
  }
}

export function* ProvidersFaxMailWorker({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ProvidersFaxMailApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");

      yield put(setFaxMailProviders(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* EmptyDownloadWorker() {
  yield put(setReferralDownload(""));
  yield put(setReferralPreview(""));
  yield put(setSendFaxMail(""));
}

export function* ProviderList() {
  try {
    const res = yield call(ProviderListApi);

    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");

      yield put(SetGetMsgList(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}
