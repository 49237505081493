import { takeLatest } from "redux-saga/effects";
import {
  getPatList,
  getProvidersList,
  getReferalData,
  GetRefferal,
  GetRefMngmtPreview,
  GetUrlimg,
  getSeachProvider,
  getReferrerDiagnosis,
  getPatientDetails,
  getSingleReferral,
  startUpdateReferral,
  GetRevision,
  GetFacilitySearchData,
  GetAppointmentSearchData,
  GetNoteUpdateData,
  GetIncomingFax,
  GetInsertIncomingFax,
  GetSearchIncomingFax,
  GetOutgoingFax,
  GetInsertOutgoingFax,
  GetProvDataFax,
  GetSaveFaxQueue,
  GetEmptyProviderList,
  GetOutgoingFaxDetails,
  GetIncomingFaxDetails,
  GetFaxAssign,
  GetFaxProviderdetails,
  GetPreviewDownload,
  GetPreviewDownload_2out,
  referralInsertStart,
  GetRefferalDelete,
  GetReferalContact,
  GetInsertReferalContact,
  GetUpdateReferalContact,
  GetDeleteReferalContact,
  GetSingleReferalContact,
  GetRefContactemailcheck,
  GetRefContactemptyvalidation,
  GetRefContactphonecheck,
  GetRefContactnpicheck,
  getSendFaxMail,
  getReferalDataOne,
  getReferralDownload,
  emptyReferralDownload,
  getReferralPreview,
  getDeleteTempDocumentData,
  GetEmptyProviderListReferal,
  GetRefContactfaxcheck,
  getFaxMailProviders,
  GetMsgList,
  GetSurgicalhistory,
  GetSurgicaldata,
  GetchangeSurgicaldata,
  GethandledeleteSurgicaldata,
  Geteditsaved,
  GetpostSocialhistory,
  GetSocialhistory,
} from "../../StateManagement/Reducers/PatientState";
import {
  PatListWorker,
  ProvidersListWorker,
  ReferalDataWorker,
  RefferalList,
  Refmangmnt_Preview,
  ProviderListWorker,
  ReferrerDiagnosisWorker,
  PatientDetailsWorker,
  ReferralInsertWorker,
  SingleReferralWorker,
  ReferalDeleteList,
  Revision,
  FacilitySearchData,
  AppointmentSearchData,
  NoteUpdateWorker,
  IncomingFaxget,
  IncomingFaxinsert,
  IncomingFaxsearch,
  OutgoingFaxget,
  OutgoingFaxinsert,
  ProviderFaxDataWorker,
  SaveFaxQueueWorker,
  EmptyProviderList,
  OutgoingFax,
  IncomingFax,
  IncomingFaxAssign,
  IncomingFaxProvider,
  PreviewDownloadApi,
  PreviewDownload_2out_Api,
  EmptyProviderListReferalWorkers,
  ReferalContactList,
  ReferalInsertContactList,
  ReferalUpdateContactList,
  ReferalDeleteContactList,
  ReferalContactSingleList,
  RefContactemailcheck,
  RefContactemptyvalidation,
  RefContactemptyphone,
  RefContactemptyfax,
  RefContactemptynpi,
  RefContactphonecheck,
  RefContactfaxcheck,
  RefContactnpicheck,
  SendFaxMailWorker,
  ReferralDataOneWorker,
  ReferralDownloadWorker,
  EmptyDownloadWorker,
  ReferralPreviewWorker,
  DeleteTempWorker,
  ProvidersFaxMailWorker,
  UpdateReferralWorker,
  ProviderList,
  Surgicalhistory,
  Surgicaldata,
  changeSurgicaldata,
  deleteSurgicaldata,
  editsave,
  Socialhistorypost,
  SocialHistoryget,
} from "./PatientSagaWorkers";

export function* RevisionSaga() {
  yield takeLatest(GetRevision.type, Revision);
}

export function* FacilitySearchDataSaga() {
  yield takeLatest(GetFacilitySearchData.type, FacilitySearchData);
}
export function* SurgicalhistorySaga() {
  yield takeLatest(GetSurgicalhistory.type, Surgicalhistory);
}
export function* SurgicaldataSaga() {
  yield takeLatest(GetSurgicaldata.type, Surgicaldata);
}
export function* changeSurgicalSaga() {
  yield takeLatest(GetchangeSurgicaldata.type, changeSurgicaldata);
}
export function* deleteSurgicalSaga() {
  yield takeLatest(GethandledeleteSurgicaldata.type, deleteSurgicaldata);
}
export function* editsaveSaga() {
  yield takeLatest(Geteditsaved.type, editsave);
}
export function* socialhistorypostSaga() {
  yield takeLatest(GetpostSocialhistory.type, Socialhistorypost);
}

export function* socialhistorygetSaga() {
  yield takeLatest(GetSocialhistory.type, SocialHistoryget);
}

export function* AppointmentSearchDataSaga() {
  yield takeLatest(GetAppointmentSearchData.type, AppointmentSearchData);
}

export function* NoteUpdateSaga() {
  yield takeLatest(GetNoteUpdateData.type, NoteUpdateWorker);
}

export function* GetIncomingFaxSaga() {
  yield takeLatest(GetIncomingFax.type, IncomingFaxget);
}
export function* SearchIncomingFaxSaga() {
  yield takeLatest(GetSearchIncomingFax.type, IncomingFaxsearch);
}
export function* InsertIncomingFaxSaga() {
  yield takeLatest(GetInsertIncomingFax.type, IncomingFaxinsert);
}
export function* GetOutgoingFaxSaga() {
  yield takeLatest(GetOutgoingFax.type, OutgoingFaxget);
}

export function* InsertOutgoingFaxSaga() {
  yield takeLatest(GetInsertOutgoingFax.type, OutgoingFaxinsert);
}
export function* IncomingFaxAssignSaga() {
  yield takeLatest(GetFaxAssign.type, IncomingFaxAssign);
}
export function* IncomingFaxProviderSaga() {
  yield takeLatest(GetFaxProviderdetails.type, IncomingFaxProvider);
}
export function* ProvFaxDataSaga() {
  yield takeLatest(GetProvDataFax.type, ProviderFaxDataWorker);
}

export function* SaveFaxQueueSaga() {
  yield takeLatest(GetSaveFaxQueue.type, SaveFaxQueueWorker);
}

export function* EmptyProviderListSaga() {
  yield takeLatest(GetEmptyProviderList.type, EmptyProviderList);
}

export function* IncomingFaxSaga() {
  yield takeLatest(GetIncomingFaxDetails.type, IncomingFax);
}

export function* OutgoingFaxSaga() {
  yield takeLatest(GetOutgoingFaxDetails.type, OutgoingFax);
}

export function* PreviewDownloadSagaM() {
  yield takeLatest(GetPreviewDownload.type, PreviewDownloadApi);
}

export function* PreviewDownload_2out_Saga() {
  yield takeLatest(GetPreviewDownload_2out.type, PreviewDownload_2out_Api);
}
export function* EmptyProviderListReferalSaga() {
  yield takeLatest(
    GetEmptyProviderListReferal.type,
    EmptyProviderListReferalWorkers
  );
}

export function* RefferalListSaga() {
  yield takeLatest(GetRefferal.type, RefferalList);
}

export function* ProvidersListSaga() {
  yield takeLatest(getProvidersList.type, ProvidersListWorker);
}

export function* PatListSaga() {
  yield takeLatest(getPatList.type, PatListWorker);
}

export function* ReferalDataSaga() {
  yield takeLatest(getReferalData.type, ReferalDataWorker);
}

export function* Refmangmt_Preview_details() {
  yield takeLatest(GetRefMngmtPreview.type, Refmangmnt_Preview);
}

export function* ProviderSearchSaga() {
  yield takeLatest(getSeachProvider.type, ProviderListWorker);
}

export function* ReferrerDiagnosisSaga() {
  yield takeLatest(getReferrerDiagnosis.type, ReferrerDiagnosisWorker);
}

export function* PatientDetailsSaga() {
  yield takeLatest(getPatientDetails.type, PatientDetailsWorker);
}

export function* ReferralInsertSaga() {
  yield takeLatest(referralInsertStart.type, ReferralInsertWorker);
}

export function* SingleReferralSaga() {
  yield takeLatest(getSingleReferral.type, SingleReferralWorker);
}
export function* UpdateReferralSaga() {
  yield takeLatest(startUpdateReferral.type, UpdateReferralWorker);
}

export function* ReferalDeleteSaga() {
  yield takeLatest(GetRefferalDelete.type, ReferalDeleteList);
}

export function* ReferalContactSaga() {
  yield takeLatest(GetReferalContact.type, ReferalContactList);
}

export function* ReferalContactInsertSaga() {
  yield takeLatest(GetInsertReferalContact.type, ReferalInsertContactList);
}
export function* ReferalContactUpdateSaga() {
  yield takeLatest(GetUpdateReferalContact.type, ReferalUpdateContactList);
}
export function* ReferalContactDeleteSaga() {
  yield takeLatest(GetDeleteReferalContact.type, ReferalDeleteContactList);
}
export function* ReferalContactSingleSaga() {
  yield takeLatest(GetSingleReferalContact.type, ReferalContactSingleList);
}
export function* RefContactemailcheckSaga() {
  yield takeLatest(GetRefContactemailcheck.type, RefContactemailcheck);
}
export function* RefContactphonecheckSaga() {
  yield takeLatest(GetRefContactphonecheck.type, RefContactphonecheck);
}
export function* RefContactnpicheckSaga() {
  yield takeLatest(GetRefContactnpicheck.type, RefContactnpicheck);
}
export function* RefContactfaxcheckSaga() {
  yield takeLatest(GetRefContactfaxcheck.type, RefContactfaxcheck);
}
export function* RefContactemptyvalidationSaga() {
  yield takeLatest(
    GetRefContactemptyvalidation.type,
    RefContactemptyvalidation
  );
}
export function* RefContactemptyphoneSaga() {
  yield takeLatest(GetRefContactemptyvalidation.type, RefContactemptyphone);
}
export function* RefContactemptyfaxSaga() {
  yield takeLatest(GetRefContactemptyvalidation.type, RefContactemptyfax);
}
export function* RefContactemptynpiSaga() {
  yield takeLatest(GetRefContactemptyvalidation.type, RefContactemptynpi);
}

export function* SendFaxMailSaga() {
  yield takeLatest(getSendFaxMail.type, SendFaxMailWorker);
}

export function* ReferralDataOneSaga() {
  yield takeLatest(getReferalDataOne.type, ReferralDataOneWorker);
}

export function* ReferralDownloadSaga() {
  yield takeLatest(getReferralDownload.type, ReferralDownloadWorker);
}

export function* ReferralPreviewSaga() {
  yield takeLatest(getReferralPreview.type, ReferralPreviewWorker);
}

export function* DeleteTempSaga() {
  yield takeLatest(getDeleteTempDocumentData.type, DeleteTempWorker);
}

export function* EmptyDownloadSaga() {
  yield takeLatest(emptyReferralDownload.type, EmptyDownloadWorker);
}

export function* ProvidersFaxMailSaga() {
  yield takeLatest(getFaxMailProviders.type, ProvidersFaxMailWorker);
}

export function* ProviderListSaga() {
  yield takeLatest(GetMsgList.type, ProviderList);
}
