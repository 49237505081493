import styled from "styled-components";
import {
  Table,
  TableHead,
  TableHeadCell,
  TableRow,
  TableBody,
  TableBodyCell,
} from "../../../StyledComponents";
import {
  DropDownListContainer,
  ListItem,
  DropDownList,
  DropDownDiv,
} from "../../../components/setting/PatientPortalSettings/styles";
import { globalColor, globalFont } from "../../utils/RepeatFunctions/Schedule";

export const DropDownListContainer1 = styled(DropDownListContainer)``;
export const TxtSpan = styled.span`
  color: ${(p) => (p.color ? p.color : "#000000")};
  font-size: 14px;
  line-height: 16px;
  font-family: ${globalFont};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  margin: ${({ margin }) => margin && margin};
  white-space: ${({ nowrap }) => (nowrap ? "nowrap" : "")};
  overflow: hidden;
  width: 140px;
  text-overflow: ellipsis;
`;
export const DropDown = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0px;
  svg {
    font-size: 14px;
    float: right;
    margin-right: 5px;
    margin-top: 7px;
  }
`;

export const ListItem1 = styled(ListItem)`
  color: #000000;
`;
export const DropDownDiv1 = styled(DropDownDiv)`
  height: ${(p) => (p.height ? p.height : "28px")};
  cursor: pointer;
  padding: 0px 0 0 12px;
  svg {
    margin-top: 0px;
  }
`;
export const DropDownList1 = styled(DropDownList)`
  max-height: 147px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Input = styled.input`
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => (p.padding ? p.padding : "0px 10px 3px 10px")};
  cursor: ${(p) => p.cursor && p.cursor};
  background: ${(p) => p.background && p.background};
  display: ${(p) => p.display && p.display};
  color: ${(p) => p.color && p.color};
  &:focus {
    outline: none;
  }
`;

export const RedStar = styled.span`
  color: red;
  vertical-align: 1px;
  font-size: 10px;
`;

export const Button = styled.button`
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "4px")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  background: ${(p) => p.background && p.background};
  color: ${(p) => (p.color ? p.color : "#ffffff")};
  border: ${(p) => (p.border ? p.border : "none")};
  padding: ${(p) => p.padding && p.padding};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
`;
export const Imagecalendar = styled.img`
  position: absolute;
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "165px")};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  width: 16px;
  height: 16px;
  object-fit: contain;
  cursor: pointer;
`;
export const Table1 = styled(Table)`
  border-top: ${(p) => p.borderTop && p.borderTop};
  height: unset;
  border-right: none !important;
  border-left: none !important;
`;
export const TableHead1 = styled(TableHead)``;

export const TableHeadCell1 = styled(TableHeadCell)`
  color: ${globalColor};
  font-weight: 600;
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
`;
export const TableRow1 = styled(TableRow)`
  text-align: center;
  border-bottom: ${(p) =>
    p.bbtom ? "1px solid rgba(46, 46, 46, 0.25)" : "none"};
  height: ${(p) => (p.height ? p.height : "36px")};
`;

export const TableBody1 = styled(TableBody)``;

export const TableBodyCell1 = styled(TableBodyCell)`
  border-bottom: 0.5px rgba(46, 46, 46, 0.25) solid;
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  cursor: ${(p) => p.cursor && p.cursor};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
`;

export const TabLabel = styled.label`
  cursor: ${(p) => p.cursor && p.cursor};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin: ${(p) => p.margin && p.margin};
  position: ${(p) => p.position && p.position};
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : "2px !important")};
`;

export const CheckBoxLabel = styled.label`
  position: relative;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  margin-top: 4px;
  border-radius: 2px;
  background: #ffff;
  border: 1.9px solid rgba(46, 46, 46, 0.25);

  &::after {
    content: "";
    display: block;
    // border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 3px;
    /* margin: 2px; */
    // background: #ffffff;
    /* box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2); */
    // &:checked{
    //   background: ${globalColor};
    // }
  }
`;

export const TogggleCheck = styled.input`
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  height: 16px;
  width: 16px;

  position: absolute;
  background: #ffff;
  &:checked + ${CheckBoxLabel} {
    background: #fff;
    border: 1.9px solid ${globalColor};
    // background: ${globalColor};
    &::after {
      content: "";
      display: block;
      // border-radius: 50%;
      width: 8px;
      height: 8px;
      background: ${globalColor};
    }
  }
`;
export const ErrorMessage = styled.p`
  width: ${(p) => (p.width ? p.width : "100%")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: left;
`;
